import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import { createTheme } from '@ovotech/nebula';
import { themeConfig } from '@ovotech/ovoenergy-theme';
import Wrapper from './smart-meter-health-check/wrapper/Wrapper';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createApolloClient } from './apollo';
import { AnalyticsContext, createAnalytics } from './smart-meter-health-check/analytics';
import { apiUrl, authTokenStorageKey } from './config';
import { isProd } from './smart-meter-health-check/utils';

const ovoTheme = createTheme(themeConfig);
const apolloClient = createApolloClient(`${apiUrl}/graphql`, authTokenStorageKey);
const analytics = createAnalytics();

const oneTrustId = `38157829-8eb2-4d61-9af7-80ae2ce63dfb${!isProd(apiUrl) ? '-test' : ''}`;

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: 'function OptanonWrapper() {}',
          },
        ]}
      >
        {/* OneTrust Cookies Consent Notice start for smart-meter-help.ovoenergy.com */}
        <script
          src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={oneTrustId}
        />
        {/* OneTrust Cookies Consent Notice end for smart-meter-help.ovoenergy.com */}
        <link href="https://fonts.googleapis.com/css?family=Montserrat:700" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700" rel="stylesheet" />
        <title>OVO Energy - Smart meter help</title>
        <meta
          name="description"
          content="Having trouble with your smart meter? Send details through our app and we'll find a way to fix it."
        />
      </Helmet>
      <ApolloProvider client={apolloClient as any}>
        <ThemeProvider theme={ovoTheme}>
          <AnalyticsContext.Provider value={analytics}>
            <Wrapper />
          </AnalyticsContext.Provider>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
  );
};

export default App;
