import { P } from '@ovotech/nebula';
import React from 'react';
import AccountDetailsForm from '../components/account-details-form/AccountDetailsForm';

interface Props {
  onComplete: () => void;
}

const Reauthenticate: React.FC<Props> = ({ onComplete }) => {
  function onVerifyResult(passed: boolean) {
    if (passed) {
      onComplete();
    }
  }

  return (
    <AccountDetailsForm
      disableAccountNumberField={true}
      message={<P>Please enter your postcode for a quick final check</P>}
      trackingPrefix="Reauthentication"
      onVerifyResult={onVerifyResult}
    />
  );
};

export default Reauthenticate;
