import { Modal, TextLink } from '@ovotech/nebula';
import React, { useState } from 'react';
import { FuelType, MeterType } from '../../../__generated__/graphql';
import { useTrack } from '../../analytics';
import ModalContent from '../modal-content/ModalContent';
import './HowFindThisLink.css';

export enum ImageType {
  READING = 'reading',
  READING_TWO_RATE = 'reading-two-rate',
  COMMISSION = 'commission',
  MSN = 'msn',
  HAN = 'han',
  WAN = 'wan',
}

function setModalHeader(imageType: ImageType): string {
  switch (imageType) {
    case ImageType.READING:
    case ImageType.READING_TWO_RATE:
      return 'How to get a meter reading';
    case ImageType.COMMISSION:
      return 'How to find the numberpad on your meter';
    case ImageType.MSN:
      return 'How to find your meter serial numbers';
    case ImageType.WAN:
      return 'How to find the WAN light';
    case ImageType.HAN:
      return 'How to find the HAN light';
    default:
      return '';
  }
}

interface Props {
  meterType?: MeterType;
  fuel?: FuelType;
  imageType: ImageType;
}

const HowFindThisLink: React.FC<Props> = ({ meterType, fuel, imageType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const track = useTrack();
  function linkClicked() {
    track('How find this clicked', { fuel, imageType });
    setIsOpen(true);
  }
  return (
    <div className="how-find-this-link">
      <TextLink onClick={linkClicked} data-test="how-find-this-link">
        How can I find this?
      </TextLink>
      <Modal
        className="how-find-this-link_modal"
        data-test="how-find-this-modal"
        title={setModalHeader(imageType)}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ModalContent meterType={meterType} fuel={fuel} imageType={imageType} />
      </Modal>
    </div>
  );
};

export default HowFindThisLink;
