import { P, Radio, RadioField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import React from 'react';
import { FuelType } from '../../__generated__/graphql';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import HowFindThisLink, { ImageType } from '../components/how-find-this-link/HowFindThisLink';
import NextButton from '../components/next-button/NextButton';
import RadioSet from '../components/radio-set/RadioSet';
import StepCounter from '../components/step-counter/StepCounter';
import { meterTypeAtom } from '../state';
import { NavigationProps } from './types';

interface Props extends NavigationProps {
  fuel: FuelType;
  state: boolean | undefined;
  setState: (state: boolean) => void;
}

const Commisioned: React.FC<Props> = ({ onBack, onNext, state = true, setState, fuel }) => {
  const [meterType] = useAtom(meterTypeAtom);

  const fuelLabel = fuel.toLowerCase();
  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      <P>Press 0 on your {fuelLabel} meter.</P>
      <HowFindThisLink meterType={meterType} fuel={fuel} imageType={ImageType.COMMISSION} />
      <RadioSet>
        <RadioField
          name="isCommissioned"
          label={`What does your ${fuelLabel} meter show?`}
          defaultValue={state || state === undefined ? 'timeAndDate' : 'commission'}
          onValueChange={(value) => setState(value === 'timeAndDate')}
        >
          <Radio label="The time and date" id="timeAndDate" value="timeAndDate" />
          <Radio label="The word ‘commission’" id="commission" value="commission" />
        </RadioField>
      </RadioSet>
      <NextButton
        onClick={onNext}
        trackOptions={[`${fuel} meter commisioned state entered`, { fuel, state }]}
      />
    </FormSection>
  );
};

export default Commisioned;
