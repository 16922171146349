import axios from 'axios';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { focusAtom } from 'jotai/optics';
import { imageFilesAtom, stateAtom } from '../state';
import { apiUrl, authTokenStorageKey } from '../../config';
import { useTrack } from '../analytics';
import { defaultMaxImageBytes } from '../constants';

const caseIdsAtom = focusAtom(stateAtom, (optic) => optic.prop('caseIds'));

const formDataKey = 'images';

export function useUploadImages(maxImageBytes = defaultMaxImageBytes): {
  loading: boolean;
  complete: boolean;
  error: unknown;
  upload: () => Promise<void>;
} {
  const [caseIds = []] = useAtom(caseIdsAtom);
  const [imageFiles = []] = useAtom(imageFilesAtom);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const track = useTrack();

  const authToken = localStorage.getItem(authTokenStorageKey) as string;

  async function upload() {
    const files = Array.from(imageFiles);
    const data = new FormData();
    for (const file of files) {
      if (file.size < maxImageBytes) {
        data.append(formDataKey, file);
      } else {
        track('Image too large');
        console.warn(`File ${file.name} exceeds ${maxImageBytes} bytes limit`);
      }
    }
    if (data.getAll(formDataKey).length === 0) {
      console.warn('No valid images to upload');
      return;
    }
    setComplete(false);
    setLoading(true);
    setError(null);
    try {
      let caseCounter = 0;
      for (const caseId of caseIds) {
        caseCounter++;
        await axios.post(`${apiUrl}/smart-meter-help/case/${caseId}/images`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': `multipart/form-data`,
          },
        });
        track(
          'Image file upload success',
          { files: files.length, caseCounter, caseCount: caseIds.length },
          false,
        );
      }
    } catch (error) {
      track('Image file upload error', undefined, false);
      setError(error);
    }
    setLoading(false);
    setComplete(true);
  }
  return { loading, error, upload, complete };
}
