import { P, Radio, RadioField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React from 'react';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import HowFindThisLink, { ImageType } from '../components/how-find-this-link/HowFindThisLink';
import NextButton from '../components/next-button/NextButton';
import RadioSet from '../components/radio-set/RadioSet';
import StepCounter from '../components/step-counter/StepCounter';
import { HanState, meterTypeAtom, stateAtom } from '../state';
import { NavigationProps } from './types';

const atom = focusAtom(stateAtom, (optic) => optic.prop('hanState'));

const HanLight: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [meterType] = useAtom(meterTypeAtom);
  const [state = 'On', setState] = useAtom(atom);

  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      <P>Now look for a light labelled HAN on the front of your electricity meter.</P>
      <HowFindThisLink meterType={meterType} imageType={ImageType.HAN} />
      <RadioSet>
        <RadioField
          name="hanState"
          label="The HAN lights are:"
          defaultValue={state}
          onValueChange={(value) => setState(value as HanState)}
        >
          <Radio label="Flashing" id="hanStateFlashing" value="Flashing" />
          <Radio label="On" id="hanStateOn" value="On" />
        </RadioField>
      </RadioSet>
      <NextButton onClick={onNext} trackOptions={['HAN state entered']} />
    </FormSection>
  );
};

export default HanLight;
