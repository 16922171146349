import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React from 'react';
import { FuelType } from '../../__generated__/graphql';
import { stateAtom } from '../state';
import Commisioned from './Commisioned';
import { NavigationProps } from './types';

const atom = focusAtom(stateAtom, (optic) => optic.prop('isElecCommisioned'));

const ElecCommisioned: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [state, setState] = useAtom(atom);

  return (
    <Commisioned
      onBack={onBack}
      onNext={onNext}
      state={state}
      setState={setState}
      fuel={FuelType.Electricity}
    />
  );
};

export default ElecCommisioned;
