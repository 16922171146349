import React, { useEffect, useState } from 'react';
import { getAndEmbedQualtricsFeedbackUi } from './getQualtricsFeedbackUi';
import { useTrack } from '../../analytics';
import './FeedbackUI.css';

const FeedbackUI: React.FC = () => {
  const [isInitialised, setIsInitialised] = useState(false);
  const track = useTrack();

  useEffect(() => {
    if (!isInitialised && document.querySelector('.feedback')) {
      try {
        getAndEmbedQualtricsFeedbackUi();
        setIsInitialised(true);
      } catch (error: any) {
        const errorMessage = error.message;
        track('Qualtrics Error', { errorMessage }, false);
      }
    }
  }, [isInitialised]);

  return <div className="feedback"></div>;
};

export default FeedbackUI;
