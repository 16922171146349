import { useAtom } from 'jotai';
import { postcodeAtom } from '../state';
import { useInsertBeforeFinalScreen } from './useInsertBeforeFinalScreen';

export function usePreReauthentication(): () => void {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, setPostcode] = useAtom(postcodeAtom);
  const insertBeforeFinalScreen = useInsertBeforeFinalScreen();

  return () => {
    setPostcode(undefined);
    insertBeforeFinalScreen('reauthentication');
  };
}
