import { Heading3, P, SkeletonCTA, SkeletonHeading, SkeletonText } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import React from 'react';
import FormSection from '../components/form-section/FormSection';
import HelpCentreCTA from '../components/help-centre-cta/HelpCentreCTA';
import { stateAtom } from '../state';
import { focusAtom } from 'jotai/optics';
import { useResetStateOnBeforeUnload } from '../hooks/useResetStateOnBeforeUnload';
import LoadingModal from '../components/loading-modal/LoadingModal';
import FeedbackUI from '../components/feedback-ui/FeedbackUI';

const atom = focusAtom(stateAtom, (optic) => optic.prop('caseCreationStatus'));

const skeletonProps: { style: React.CSSProperties } = {
  style: {
    backgroundColor: '#ebebeb',
  },
};

const Complete: React.FC = () => {
  useResetStateOnBeforeUnload();

  const [caseCreationStatus] = useAtom(atom);
  return (
    <FormSection>
      {caseCreationStatus === 'not-submitted' && (
        <>
          <LoadingModal isOpen={true}>We're submitting your details.</LoadingModal>
          <SkeletonHeading headingComponent={Heading3} {...skeletonProps} />
          <SkeletonText lines={2} {...skeletonProps} />
          <SkeletonCTA {...skeletonProps} />
        </>
      )}
      {caseCreationStatus === 'error' && (
        <>
          <Heading3>We're sorry, we couldn't process your request</Heading3>
          <P>Please call us on 0330 303 5063 for help with your smart meter.</P>
          <HelpCentreCTA trackOptions={['Submission error Help Centre CTA clicked']} />
          <FeedbackUI />
        </>
      )}
      {caseCreationStatus === 'submitted' && (
        <>
          <Heading3>Thank you, we have all the information we need.</Heading3>
          <P>
            We'll use the details you have given us to investigate further and get back to you
            within 45 working days.
          </P>
          <HelpCentreCTA trackOptions={['Submission success Help Centre CTA clicked']} />
          <FeedbackUI />
        </>
      )}
    </FormSection>
  );
};

export default Complete;
