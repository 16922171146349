import { FuelType, MeterType, SupplyPoint } from '../__generated__/graphql';
import { ScreenName } from './state';

export const initialScreens: ScreenName[] = ['welcome', 'account-details'];

interface JourneyOptions {
  meterType: MeterType;
  isTwoRate: boolean;
  hasGas: boolean;
  noMsns: boolean;
  nonComms: boolean;
}

export function getJourneyOptions(supplyPoints: SupplyPoint[]): JourneyOptions {
  const elecMeter = supplyPoints.find((supplyPoint) => supplyPoint.fuel === FuelType.Electricity);
  const gasMeter = supplyPoints.find((supplyPoint) => supplyPoint.fuel === FuelType.Gas);
  const meterType = elecMeter?.type ?? MeterType.Other;
  const isTwoRate = elecMeter?.isTwoRate ?? false;
  const hasGas = gasMeter !== undefined;
  const noMsns = !!(!elecMeter?.msn || (gasMeter && !gasMeter.msn));
  const nonComms = supplyPoints.some((supplyPoint) => supplyPoint.nonComms === true);
  return {
    meterType,
    isTwoRate,
    hasGas,
    noMsns,
    nonComms,
  };
}

export function makeJourney({
  meterType,
  isTwoRate,
  hasGas,
  nonComms,
}: JourneyOptions): ScreenName[] {
  console.log(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `Making journey for meter type ${meterType.toString()}, isTwoRate ${isTwoRate.toString()}, hasGas ${hasGas.toString()}`,
  );
  if (meterType === MeterType.Other) {
    return makeUnsupportedMeterJourney();
  }

  if (!nonComms) {
    return makeMetersInCommsJourney();
  }

  return [
    ...initialScreens,
    'confirm-msns',
    'wan-state',
    'han-state',
    ...((meterType === MeterType.S1 ? ['elec-commisioned'] : []) as ScreenName[]),
    ...([isTwoRate ? 'elec-two-rate-reading' : 'elec-reading'] as ScreenName[]),
    'meter-location',
    'mobile-signal',
    ...((meterType === MeterType.S1 && hasGas ? ['gas-commisioned'] : []) as ScreenName[]),
    ...((hasGas ? ['gas-reading'] : []) as ScreenName[]),
    ...((hasGas ? ['meter-distance', 'thick-walls'] : []) as ScreenName[]),
    'tell-us-about-the-problem',
    'complete',
  ];
}

export function makeOpenCaseJourney(): ScreenName[] {
  return [...initialScreens, 'open-case'];
}

export function makeUnsupportedMeterJourney(): ScreenName[] {
  return [...initialScreens, 'confirm-msns', 'book-install'];
}

export function makeMismatchJourney(): ScreenName[] {
  return [...initialScreens, 'confirm-msns', 'enter-msns', 'complete'];
}

export function makeMissingMsnJourney(): ScreenName[] {
  return [...initialScreens, 'enter-msns', 'complete'];
}

export function makeMetersInCommsJourney(): ScreenName[] {
  return [...initialScreens, 'confirm-msns', 'meters-in-comms'];
}

export function insertBeforeLastScreen(current: ScreenName[], insert: ScreenName): ScreenName[] {
  if (current.length === 0) {
    throw new Error('Array cannot be empty');
  }
  const updated = [...current];
  updated.splice(updated.length - 1, 0, insert);
  return updated;
}
