import { TextLink } from '@ovotech/nebula';
import React from 'react';
import './BackButton.css';

interface Props {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="back-button">
      <TextLink className="back-button_link" data-test="back-button" onClick={onClick}>
        Back
      </TextLink>
    </div>
  );
};

export default BackButton;
