import { Checkbox, P, SecondaryCTAButton, TextField } from '@ovotech/nebula';
import React, { useState } from 'react';
import BackButton from '../../components/back-button/BackButton';
import HowFindThisLink, { ImageType } from '../../components/how-find-this-link/HowFindThisLink';
import FormSection from '../../components/form-section/FormSection';
import StepCounter from '../../components/step-counter/StepCounter';
import { NavigationProps } from '../types';
import './EnterMsns.css';
import NextButton from '../../components/next-button/NextButton';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { focusAtom } from 'jotai/optics';
import { stateAtom } from '../../state';
import { useAtom } from 'jotai';
import { MeterType } from '../../../__generated__/graphql';

const msnPattern = /^[A-Za-z0-9]*$/;

type FormInput = Record<string, string>;

function getMsnError(error: FieldError | undefined, fuelLabel: string): string | undefined {
  if (!error) {
    return;
  }
  if (error.type === 'pattern') {
    return `Please enter a valid ${fuelLabel} meter serial number`;
  }
  if (error.type === 'required') {
    return `We need a serial number for at least one ${fuelLabel} meter`;
  }
}

const elecCustomerMsnsAtom = focusAtom(stateAtom, (optic) => optic.prop('elecCustomerMsns'));
const gasCustomerMsnsAtom = focusAtom(stateAtom, (optic) => optic.prop('gasCustomerMsns'));

const EnterMsns: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm<FormInput>();

  const [elecMsns = [''], setElecMsns] = useAtom(elecCustomerMsnsAtom);
  const [gasMsns = [''], setGasMsns] = useAtom(gasCustomerMsnsAtom);
  const [noGas, setNoGas] = useState(false);

  function addGasMsn() {
    setGasMsns([...gasMsns, '']);
  }

  function addElecMsn() {
    setElecMsns([...elecMsns, '']);
  }

  const onSubmit: SubmitHandler<FormInput> = () => {
    onNext();
  };

  return (
    <FormSection className="enter-msns">
      <BackButton onClick={onBack} />
      <StepCounter />
      <P>What are your meter serial numbers?</P>
      <P>You can find them directly above the barcode on each meter.</P>
      <HowFindThisLink meterType={MeterType.Other} imageType={ImageType.MSN} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="enter-msns_meter_details">
          <div className="enter-msns_meter_type">Electricity meter number</div>
          {(elecMsns ?? []).map((msn, index) => {
            const id = `elecMsn${index}`;
            return (
              <Controller
                key={id}
                name={id}
                control={control}
                defaultValue={msn}
                rules={{
                  pattern: msnPattern,
                  required: index === 0,
                }}
                render={({ field }) => (
                  <div data-test="form-field-group-elec-msn" className="enter-msns_meter_msn">
                    <TextField
                      key={id}
                      id={id}
                      label=""
                      error={getMsnError(formErrors[id], 'electricity')}
                      {...field}
                      onBlur={(event) => {
                        const state = [...elecMsns];
                        state[index] = event.target.value;
                        setElecMsns(state);
                      }}
                    />
                  </div>
                )}
              />
            );
          })}
          <SecondaryCTAButton
            className="enter-msns_add_elec_msn"
            data-test="add-elec-msn"
            iconLeft="plus"
            onClick={addElecMsn}
            type="button"
          >
            Add another electricity meter
          </SecondaryCTAButton>
        </div>
        <div className="enter-msns_meter_details">
          <div className="enter-msns_meter_type">Gas meter number</div>
          {!noGas && (
            <>
              {(gasMsns ?? []).map((msn, index) => {
                const id = `gasMsn${index}`;
                return (
                  <Controller
                    key={id}
                    name={id}
                    control={control}
                    defaultValue={msn}
                    rules={{
                      pattern: msnPattern,
                      required: index === 0,
                    }}
                    render={({ field }) => (
                      <div data-test="form-field-group-gas-msn" className="enter-msns_meter_msn">
                        <TextField
                          key={id}
                          id={id}
                          label=""
                          error={getMsnError(formErrors[id], 'gas')}
                          disabled={noGas}
                          {...field}
                          onBlur={(event) => {
                            const state = [...gasMsns];
                            state[index] = event.target.value;
                            setGasMsns(state);
                          }}
                        />
                      </div>
                    )}
                  />
                );
              })}
              <SecondaryCTAButton
                className="enter-msns_add_gas_msn"
                data-test="add-gas-msn"
                iconLeft="plus"
                onClick={addGasMsn}
                type="button"
              >
                Add another gas meter
              </SecondaryCTAButton>
            </>
          )}
          <div className="enter-msns_no_gas">
            <Checkbox
              label="I don’t have a gas meter"
              value="true"
              id="noGas"
              checked={noGas}
              onChange={(e) => setNoGas(e.target.checked)}
            />
          </div>
        </div>
        <NextButton isSubmit trackOptions={['Mismatched MSNs entered']} />
      </form>
    </FormSection>
  );
};

export default EnterMsns;
