import { P, Radio, RadioField, Stack } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React, { useState } from 'react';
import BackButton from '../../components/back-button/BackButton';
import HowFindThisLink, { ImageType } from '../../components/how-find-this-link/HowFindThisLink';
import FormSection from '../../components/form-section/FormSection';
import StepCounter from '../../components/step-counter/StepCounter';
import { journeyScreensAtom, meterTypeAtom, stateAtom } from '../../state';
import { NavigationProps } from '../types';
import './ConfirmMsns.css';
import NextButton from '../../components/next-button/NextButton';
import RadioSet from '../../components/radio-set/RadioSet';
import { getJourneyOptions, makeJourney, makeMismatchJourney } from '../../journeys';
import { SupplyPoint } from '../../../__generated__/graphql';

const supplyPointsAtom = focusAtom(stateAtom, (optic) => optic.prop('supplyPoints'));

const ConfirmMsns: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [meterType] = useAtom(meterTypeAtom);
  const [isCorrect, setIsCorrect] = useState(true);
  const [supplyPoints] = useAtom(supplyPointsAtom);
  const [, setJourneyScreens] = useAtom(journeyScreensAtom);

  function buttonClicked() {
    if (!isCorrect) {
      setJourneyScreens(makeMismatchJourney());
    } else {
      setJourneyScreens(makeJourney(getJourneyOptions(supplyPoints as SupplyPoint[])));
    }
    onNext();
  }

  return (
    <FormSection className="confirm-msns">
      <BackButton onClick={onBack} />
      <StepCounter />
      <P>
        These are the meter serial numbers we have for your account. Please check them to make sure
        they match the numbers on your meters.
      </P>
      <HowFindThisLink meterType={meterType} imageType={ImageType.MSN} />
      <Stack spaceBetween={5}>
        {(supplyPoints ?? [])?.map((s, index) => (
          <div className="confirm-msns_meter_details" key={`msn${index}`}>
            <div className="confirm-msns_meter_type">{s.fuel} meter number</div>
            <div className="confirm-msns_meter_msn">{s.msn}</div>
          </div>
        ))}
        <RadioSet>
          <RadioField
            name="isCorrect"
            label="Do all these meter numbers match what you have on your meters?"
            defaultValue={isCorrect ? 'true' : 'false'}
            onValueChange={(value) => setIsCorrect(value === 'true')}
          >
            <Radio
              label="Yes"
              id="isCorrectTrue"
              data-test="confirm-msns-radio-true"
              value="true"
            />
            <Radio
              label="No"
              id="isCorrectFalse"
              data-test="confirm-msns-radio-false"
              value="false"
            />
          </RadioField>
        </RadioSet>
        <NextButton
          onClick={buttonClicked}
          trackOptions={['MSN confirmation entered', { isCorrect }]}
        />
      </Stack>
    </FormSection>
  );
};

export default ConfirmMsns;
