import { Radio, RadioField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React from 'react';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import NextButton from '../components/next-button/NextButton';
import RadioSet from '../components/radio-set/RadioSet';
import StepCounter from '../components/step-counter/StepCounter';
import { stateAtom } from '../state';
import { NavigationProps } from './types';

const atom = focusAtom(stateAtom, (optic) => optic.prop('isThickWalls'));

const ThickWalls: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [state = false, setState] = useAtom(atom);

  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      <RadioSet>
        <RadioField
          name="isThickWalls"
          label="Are there any thick walls that could be blocking the signal between your gas and electricity meters?"
          defaultValue={state ? 'true' : 'false'}
          onValueChange={(value) => setState(value === 'true')}
        >
          <Radio label="Yes" id="true" value="true" />
          <Radio label="No" id="false" value="false" />
        </RadioField>
      </RadioSet>
      <NextButton onClick={onNext} trackOptions={['Thick walls entered']} />
    </FormSection>
  );
};

export default ThickWalls;
