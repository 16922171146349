import React from 'react';
import { FuelType, MeterType } from '../../../__generated__/graphql';
import { ImageType } from '../how-find-this-link/HowFindThisLink';
import MeterDetails from '../MeterDetails';
import s1ElecMsn from '../../meter-images/s1-elec-msn.svg';
import s1ElecHan from '../../meter-images/s1-elec-han.svg';
import s1ElecWan from '../../meter-images/s1-elec-wan.svg';
import s1Elec6Button from '../../meter-images/s1-elec-6-button.svg';
import s1Elec9Button from '../../meter-images/s1-elec-9-button.svg';
import s1Elec0Button from '../../meter-images/s1-elec-0-button.svg';
import s1GasMsn from '../../meter-images/s1-gas-msn.svg';
import s1Gas9Button from '../../meter-images/s1-gas-9-button.svg';
import s1Gas0Button from '../../meter-images/s1-gas-0-button.svg';
import s2ElecMsn from '../../meter-images/s2-elec-msn.svg';
import s2ElecHan from '../../meter-images/s2-elec-han.svg';
import s2ElecWan from '../../meter-images/s2-elec-wan.svg';
import s2ElecAButton from '../../meter-images/s2-elec-a-button.svg';
import s2GasMsn from '../../meter-images/s2-gas-msn.svg';
import s2GasCButton from '../../meter-images/s2-gas-c-button.svg';
import './ModalContent.css';

interface Props {
  meterType?: MeterType;
  fuel?: FuelType;
  imageType: ImageType;
}

const ModalContent: React.FC<Props> = ({ meterType, fuel, imageType }) => {
  switch (true) {
    case meterType === MeterType.S1 && imageType === ImageType.MSN:
      return (
        <div className="meter-details">
          <MeterDetails
            heading="On your electricity meter"
            hintText="You can find your meter serial number on the front, left-hand side of your electricity meter. It sits directly above the barcode."
            imgSrc={s1ElecMsn}
            imgAlt="Where to find the meter serial number on your electricity meter"
            imgDataTest="s1-elec-msn-image"
          />
          <MeterDetails
            heading="On your gas meter"
            hintText="You can find your meter serial number on the front, right-hand side of your gas meter. It sits directly above the barcode."
            imgSrc={s1GasMsn}
            imgAlt="Where to find the meter serial number on your gas meter"
            imgDataTest="s1-gas-msn-image"
          />
        </div>
      );

    case meterType === MeterType.S1 &&
      fuel === FuelType.Electricity &&
      imageType === ImageType.READING:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can get a meter reading by pressing 9 on the number pad on your electricity meter."
          imgSrc={s1Elec9Button}
          imgAlt="Where to find the 9 button on your electricity meter"
          imgDataTest="s1-elec-9-button-image"
        />
      );

    case meterType === MeterType.S1 &&
      fuel === FuelType.Electricity &&
      imageType === ImageType.READING_TWO_RATE:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can get a meter reading by pressing 6 on the number pad on your electricity meter."
          imgSrc={s1Elec6Button}
          imgAlt="Where to find the 6 button on your electricity meter"
          imgDataTest="s1-elec-6-button-image"
        />
      );

    case meterType === MeterType.S1 && fuel === FuelType.Gas && imageType === ImageType.READING:
      return (
        <MeterDetails
          heading="On your gas meter"
          hintText="You can get a meter reading by pressing 9 on the number pad on your gas meter."
          imgSrc={s1Gas9Button}
          imgAlt="Where to find the 9 button on your gas meter"
          imgDataTest="s1-gas-9-button-image"
        />
      );

    case meterType === MeterType.S1 &&
      fuel === FuelType.Electricity &&
      imageType === ImageType.COMMISSION:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can find 0 on the numberpad of your electricity meter on the front, towards the right."
          imgSrc={s1Elec0Button}
          imgAlt="Where to find the 0 button on your electricity meter"
          imgDataTest="s1-elec-0-button-image"
        />
      );

    case meterType === MeterType.S1 && fuel === FuelType.Gas && imageType === ImageType.COMMISSION:
      return (
        <MeterDetails
          heading="On your gas meter"
          hintText="You can find 0 on the numberpad of your gas meter on the front, towards the right."
          imgSrc={s1Gas0Button}
          imgAlt="Where to find the 0 button on your gas meter"
          imgDataTest="s1-gas-0-button-image"
        />
      );

    case meterType === MeterType.S1 && imageType === ImageType.WAN:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can find the WAN light on the front of your electricity meter. It’s normally in the middle of three lights."
          imgSrc={s1ElecWan}
          imgAlt="Where to find the WAN light on your electricity meter"
          imgDataTest="s1-elec-wan-image"
        />
      );

    case meterType === MeterType.S1 && imageType === ImageType.HAN:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can find the HAN light on the front of your electricity meter. It’s normally on the right of three lights."
          imgSrc={s1ElecHan}
          imgAlt="Where to find the HAN light on your electricity meter"
          imgDataTest="s1-elec-han-image"
        />
      );

    case meterType === MeterType.S2 && imageType === ImageType.MSN:
      return (
        <div className="meter-details">
          <MeterDetails
            heading="On your electricity meter"
            hintText="You can find your meter serial number on the front, left-hand side of your electricity meter. It sits directly above the barcode."
            imgSrc={s2ElecMsn}
            imgAlt="Where to find the meter serial number on your electricity meter"
            imgDataTest="s2-elec-msn-image"
          />
          <MeterDetails
            heading="On your gas meter"
            hintText="You can find your meter serial number on the front, right-hand side of your gas meter. It sits directly above the barcode."
            imgSrc={s2GasMsn}
            imgAlt="Where to find the meter serial number on your gas meter"
            imgDataTest="s2-gas-msn-image"
          />
        </div>
      );

    case meterType === MeterType.S2 &&
      fuel === FuelType.Electricity &&
      imageType === ImageType.READING:
    case meterType === MeterType.S2 &&
      fuel === FuelType.Electricity &&
      imageType === ImageType.READING_TWO_RATE:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can get a meter reading by pressing “A” on on your electricity meter."
          imgSrc={s2ElecAButton}
          imgAlt="Where to find the A button on your electricity meter"
          imgDataTest="s2-elec-a-button-image"
        />
      );

    case meterType === MeterType.S2 && fuel === FuelType.Gas && imageType === ImageType.READING:
      return (
        <MeterDetails
          heading="On your gas meter"
          hintText="You can get a meter reading by pressing “C” on on your gas meter."
          imgSrc={s2GasCButton}
          imgAlt="Where to find the C button on your gas meter"
          imgDataTest="s2-gas-c-button-image"
        />
      );

    case meterType === MeterType.S2 && imageType === ImageType.WAN:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can find the WAN light on the front of your electricity meter. It’s normally the 1st light in a row of 5 lights."
          imgSrc={s2ElecWan}
          imgAlt="Where to find the WAN light on your electricity meter"
          imgDataTest="s2-elec-wan-image"
        />
      );

    case meterType === MeterType.S2 && imageType === ImageType.HAN:
      return (
        <MeterDetails
          heading="On your electricity meter"
          hintText="You can find the HAN light on the front of your electricity meter. It’s normally on the right of three lights."
          imgSrc={s2ElecHan}
          imgAlt="Where to find the HAN light on your electricity meter"
          imgDataTest="s2-elec-han-image"
        />
      );

    case meterType === MeterType.Other && imageType === ImageType.MSN:
      return (
        <div className="meter-details">
          <MeterDetails
            heading="On your electricity meter"
            hintText="You can find your meter serial number on the front, left-hand side of your electricity meter. It sits directly above the barcode."
            imgSrc={s1ElecMsn}
            imgAlt="Where to find the meter serial number on your S1 electricity meter"
            imgDataTest="s1-elec-msn-image"
          />
          <MeterDetails
            hintText="You can find your meter serial number on the front, left-hand side of your electricity meter. It sits directly above the barcode."
            imgSrc={s2ElecMsn}
            imgAlt="Where to find the meter serial number on your S2 electricity meter"
            imgDataTest="s2-elec-msn-image"
          />
          <MeterDetails
            heading="On your gas meter"
            hintText="You can find your meter serial number on the front, right-hand side of your gas
            meter. It sits directly above the barcode."
            imgSrc={s1GasMsn}
            imgAlt="Where to find the meter serial number on your S1 gas meter"
            imgDataTest="s1-gas-msn-image"
          />
          <MeterDetails
            hintText="You can find your meter serial number on the front, right-hand side of your gas meter. It sits directly above the barcode."
            imgSrc={s2GasMsn}
            imgAlt="Where to find the meter serial number on your S2 gas meter"
            imgDataTest="s2-gas-msn-image"
          />
        </div>
      );

    default:
      return null;
  }
};

export default ModalContent;
