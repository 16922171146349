import { TextLink } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import React from 'react';
import { initialState, stateAtom } from '../state';

const TempResetLink: React.FC = () => {
  const [, setState] = useAtom(stateAtom);

  return process.env.NODE_ENV !== 'production' ? (
    <div style={{ marginTop: '2rem', cursor: 'pointer', fontSize: '1.2rem' }}>
      <TextLink onClick={() => setState(initialState)}>⚠️ Reset state ⚠️</TextLink>
    </div>
  ) : (
    <></>
  );
};

export default TempResetLink;
