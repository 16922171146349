import { focusAtom } from 'jotai/optics';
import { atomWithStorage } from 'jotai/utils';
import { MeterType, SupplyPoint } from '../__generated__/graphql';
import { initialScreens } from './journeys';

export type WanState = 'Flashing' | 'Solid' | 'Off';

export type HanState = 'Flashing' | 'On';

export type MeterLocation =
  | 'Outside - front of home'
  | 'Outside - back of home'
  | 'Basement'
  | 'Inside - ground floor'
  | 'Inside - first floor'
  | 'Inside - second floor or above';

export type MobileSignal = 'Good' | 'Average' | 'Poor';

export type ScreenName =
  | 'welcome'
  | 'account-details'
  | 'open-case'
  | 'confirm-msns'
  | 'book-install'
  | 'enter-msns'
  | 'wan-state'
  | 'han-state'
  | 'elec-commisioned'
  | 'gas-commisioned'
  | 'elec-reading'
  | 'elec-two-rate-reading'
  | 'gas-reading'
  | 'meter-location'
  | 'mobile-signal'
  | 'meter-distance'
  | 'thick-walls'
  | 'tell-us-about-the-problem'
  | 'image-upload'
  | 'reauthentication'
  | 'meters-in-comms'
  | 'complete';

export type CaseCreationStatus = 'not-submitted' | 'submitted' | 'error';

export interface State {
  screenIndex: number;
  journeyScreens: ScreenName[];
  accountNumber?: string;
  postcode?: string;
  supplyPoints?: SupplyPoint[];
  meterType?: MeterType;
  elecCustomerMsns?: string[];
  gasCustomerMsns?: string[];
  wanState?: WanState;
  hanState?: HanState;
  isElecCommisioned?: boolean;
  isGasCommisioned?: boolean;
  elecAnytimeReading?: string;
  elecR1Reading?: string;
  elecR2Reading?: string;
  gasReading?: string;
  meterLocation?: MeterLocation;
  mobileSignal?: MobileSignal;
  isDistanceMoreThanTenMeters?: boolean;
  isThickWalls?: boolean;
  caseCreationStatus: CaseCreationStatus;
  caseIds?: string[];
  previousContact?: boolean;
  ihdIssue?: boolean;
  customerInput?: string;
  imageFiles?: File[];
}

export const initialState: State = {
  screenIndex: 0,
  journeyScreens: initialScreens,
  caseCreationStatus: 'not-submitted',
};

export const stateAtom = atomWithStorage<State>('state', initialState);
export const screenIndexAtom = focusAtom(stateAtom, (optic) => optic.prop('screenIndex'));
export const journeyScreensAtom = focusAtom(stateAtom, (optic) => optic.prop('journeyScreens'));
export const meterTypeAtom = focusAtom(stateAtom, (optic) => optic.prop('meterType'));
export const accountNumberAtom = focusAtom(stateAtom, (optic) => optic.prop('accountNumber'));
export const postcodeAtom = focusAtom(stateAtom, (optic) => optic.prop('postcode'));
export const imageFilesAtom = focusAtom(stateAtom, (optic) => optic.prop('imageFiles'));
