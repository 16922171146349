import { Col, Heading4, P, Row } from '@ovotech/nebula';
import React from 'react';

interface Props {
  hintText: string;
  imgSrc: string;
  imgAlt: string;
  imgDataTest?: string;
  heading?: string;
}

const MeterDetails: React.FC<Props> = ({ hintText, imgSrc, imgAlt, imgDataTest, heading }) => {
  return (
    <div>
      {heading && <Heading4>{heading}</Heading4>}
      <Row isNested>
        <Col span={5}>
          <img src={imgSrc} alt={imgAlt} data-test={imgDataTest} />
        </Col>
        <Col span={7}>
          <P>{hintText}</P>
        </Col>
      </Row>
    </div>
  );
};

export default MeterDetails;
