import { P, TextField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React, { ChangeEvent, useState } from 'react';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import NextButton from '../components/next-button/NextButton';
import StepCounter from '../components/step-counter/StepCounter';
import { meterTypeAtom, stateAtom } from '../state';
import { NavigationProps } from './types';
import HowFindThisLink, { ImageType } from '../components/how-find-this-link/HowFindThisLink';
import { FuelType, MeterType } from '../../__generated__/graphql';
import { validateReading } from '../utils';

const atom = focusAtom(stateAtom, (optic) => optic.prop('elecAnytimeReading'));

function getError(state: string | undefined, submitted: boolean): string | undefined {
  if (!submitted) {
    return;
  }
  if (!state) {
    return 'Your meter reading is required';
  }
  if (!validateReading(state)) {
    return 'Please enter a valid meter reading';
  }
}

const ElecReading: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [meterType] = useAtom(meterTypeAtom);
  const [state = '', setState] = useAtom(atom);
  const [submitted, setSubmitted] = useState(false);

  function onNextClicked() {
    if (!getError(state, true)) {
      onNext();
    }
    setSubmitted(true);
  }

  function valueChanged(e: ChangeEvent<HTMLInputElement>) {
    setState(e.target.value);
  }

  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      {meterType === MeterType.S1 && (
        <P>
          Press 9 on your electricity meter and provide your current meter reading. This appears the
          word 'IMP KWH'.
        </P>
      )}
      {meterType === MeterType.S2 && (
        <P>
          Press the menu button. This is "A" for electricity meters. Please provide your current
          reading, which appears after the words, "IMP KWH".
        </P>
      )}
      <HowFindThisLink
        meterType={meterType}
        fuel={FuelType.Electricity}
        imageType={ImageType.READING}
      />
      <TextField
        id="elecAnytimeReading"
        data-test="elec-anytime-reading-input"
        label="Electricity meter reading"
        error={getError(state, submitted)}
        onChange={valueChanged}
        value={state}
      />
      <NextButton onClick={onNextClicked} trackOptions={['Elec single rate reading entered']} />
    </FormSection>
  );
};

export default ElecReading;
