import { Notification, P, Stack, TextField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import React, { ReactElement, useEffect } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import {
  useVerifyAccountLazyQuery,
  VerifyAccountQueryVariables,
} from '../../../__generated__/graphql';
import { accountNumberAtom, postcodeAtom } from '../../state';
import FormSection from '../form-section/FormSection';
import NextButton from '../next-button/NextButton';
import { useTrack } from '../../analytics';
import './AccountDetailsForm.css';
import ErrorNotification from '../error-notification/ErrorNotification';
import LoadingModal from '../loading-modal/LoadingModal';

// RegExp from identity https://github.com/ovotech/identity-admin/blob/61456fc39e91d2e1b3f4bf2f99866829a5d6bd8f/app/identity/admin/domain/model/package.scala#L127
const POSTCODE_REGEXP =
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;

interface FormInput {
  accountNumber: string;
  postcode: string;
}

function getAccountNumberError(error?: FieldError): string | undefined {
  if (!error) {
    return;
  }
  if (error.type === 'required') {
    return 'Your account number is required';
  }
  if (error.type === 'pattern') {
    return 'Your account number should only contain numbers';
  }
}

function getPostcodeError(error?: FieldError): string | undefined {
  if (!error) {
    return;
  }
  if (error.type === 'required') {
    return 'Your postcode is required';
  }
  if (error.type === 'pattern') {
    return 'Please enter a valid postcode';
  }
}

interface Props {
  disableAccountNumberField?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  message: ReactElement;
  onVerifyResult: (passed: boolean) => void;
  trackingPrefix: string;
}

const AccountDetailsForm: React.FC<Props> = ({
  disableAccountNumberField,
  errorMessage,
  isLoading,
  message,
  onVerifyResult,
  trackingPrefix,
}) => {
  const [accountNumber, setAccountNumber] = useAtom(accountNumberAtom);
  const [postcode, setPostcode] = useAtom(postcodeAtom);
  const track = useTrack();

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm<FormInput>();

  const [
    getVerifyAccount,
    {
      data: verifyAccountQueryData,
      error: verifyAccountQueryError,
      loading: verifyAccountQueryLoading,
    },
  ] = useVerifyAccountLazyQuery();

  const onSubmit: SubmitHandler<FormInput> = (formInput) => {
    const variables: VerifyAccountQueryVariables = {
      details: formInput,
    };
    void getVerifyAccount({ variables });
  };

  useEffect(() => {
    if (verifyAccountQueryData) {
      const { areAccountDetailsCorrect: passed } = verifyAccountQueryData;
      track(trackingPrefix, { passed }, false);
      onVerifyResult(passed);
    }
    if (verifyAccountQueryError) {
      track(`${trackingPrefix} error`, undefined, false);
    }
  }, [verifyAccountQueryData, verifyAccountQueryLoading, verifyAccountQueryError]);

  return (
    <FormSection className="account-details">
      <LoadingModal isOpen={verifyAccountQueryLoading || !!isLoading}>
        We're fetching your account details.
      </LoadingModal>
      {message}
      {verifyAccountQueryData?.areAccountDetailsCorrect === false && (
        <ErrorNotification id="unrecognised-msg">
          Sorry, we don't recognise your details. Please check them and try again if they are
          incorrect.
        </ErrorNotification>
      )}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack spaceBetween={3}>
          <div data-test="form-field-group-account-number">
            <Controller
              name="accountNumber"
              control={control}
              defaultValue={accountNumber ?? ''}
              rules={{
                required: true,
                pattern: /^\d+$/,
              }}
              render={({ field }) => (
                <TextField
                  id="accountNumber"
                  data-test="account-number-input"
                  label="Your account number"
                  error={getAccountNumberError(formErrors.accountNumber)}
                  {...field}
                  onBlur={(event) => event.target.value && setAccountNumber(event.target.value)}
                  disabled={disableAccountNumberField}
                />
              )}
            />
          </div>
          <div data-test="form-field-group-postcode">
            <Controller
              name="postcode"
              control={control}
              defaultValue={postcode ?? ''}
              rules={{
                required: true,
                pattern: POSTCODE_REGEXP,
              }}
              render={({ field }) => (
                <TextField
                  id="postcode"
                  data-test="postcode-input"
                  label="Your postcode"
                  error={getPostcodeError(formErrors.postcode)}
                  {...field}
                  onBlur={(event) => event.target.value && setPostcode(event.target.value)}
                />
              )}
            />
          </div>
          <NextButton isSubmit trackOptions={['Account details submitted']} />
          {verifyAccountQueryError && (
            <Notification id="error-notification">
              <P>{verifyAccountQueryError.message}</P>
            </Notification>
          )}
          {errorMessage && (
            <ErrorNotification id="error-notification">
              <P>{errorMessage}</P>
            </ErrorNotification>
          )}
        </Stack>
      </form>
    </FormSection>
  );
};

export default AccountDetailsForm;
