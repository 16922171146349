import { useAtom } from 'jotai';
import React from 'react';
import { journeyScreensAtom, screenIndexAtom } from '../../state';
import './StepCounter.css';

const ignoredScreensOffset = 2;

const StepCounter: React.FC = () => {
  const [screenIndex] = useAtom(screenIndexAtom);
  const [journeyScreens] = useAtom(journeyScreensAtom);

  return (
    <div className="step-counter" data-test="step-counter">
      Step {screenIndex + 1 - ignoredScreensOffset} of{' '}
      {journeyScreens.length - (ignoredScreensOffset + 1)}
    </div>
  );
};

export default StepCounter;
