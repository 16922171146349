import { useAtom } from 'jotai';
import { insertBeforeLastScreen } from '../journeys';
import { ScreenName, journeyScreensAtom } from '../state';

export function useInsertBeforeFinalScreen(): (screen: ScreenName) => void {
  const [journeyScreens, setJourneyScreens] = useAtom(journeyScreensAtom);
  return (screen: ScreenName) => {
    setJourneyScreens(insertBeforeLastScreen(journeyScreens, screen));
  };
}
