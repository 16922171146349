import React, { useEffect } from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import AccountDetails from '../screens/AccountDetails/AccountDetails';
import Welcome from '../screens/Welcome/Welcome';
import { useAtom } from 'jotai';
import { journeyScreensAtom, screenIndexAtom, ScreenName } from '../state';
import OpenCase from '../screens/OpenCase';
import './Wrapper.css';
import WanLight from '../screens/WanLight';
import HanLight from '../screens/HanLight';
import FormSection from '../components/form-section/FormSection';
import { NavigationProps } from '../screens/types';
import ElecCommisioned from '../screens/ElecCommisioned';
import GasCommisioned from '../screens/GasCommisioned';
import ElecReading from '../screens/ElecReading';
import GasReading from '../screens/GasReading';
import ElecTwoRateReading from '../screens/ElecTwoRateReading';
import MeterLocation from '../screens/MeterLocation';
import MobileSignal from '../screens/MobileSignal';
import MeterDistance from '../screens/MeterDistance';
import ThickWalls from '../screens/ThickWalls';
import Complete from '../screens/Complete';
import ConfirmMsns from '../screens/ConfirmMsns/ConfirmMsns';
import EnterMsns from '../screens/EnterMsns/EnterMsns';
import BookInstall from '../screens/BookInstall/BookInstall';
import { useCreateHealthcheckCase } from '../hooks/useCreateHealthcheckCase';
import { useCreateMismatchCase } from '../hooks/useCreateMismatchCase';
import ImageUpload from '../screens/ImageUpload/ImageUpload';
import TellUsAboutTheProblem from '../screens/TellUsAboutTheProblem/TellUsAboutTheProblem';
import Reauthenticate from '../screens/Reauthenticate';
import { useUploadImages } from '../hooks/useUploadImages';
import { useInsertImageUploadScreen } from '../hooks/useInsertImageUploadScreen';
import { isAxiosAuthenticationError } from '../utils';
import { usePreReauthentication } from '../hooks/usePreReauthentication';
import MetersInComms from '../screens/MetersInComms/MetersInComms';

const Wrapper: React.FC = () => {
  const [screenIndex, setScreenIndex] = useAtom(screenIndexAtom);
  const [journeyScreens] = useAtom(journeyScreensAtom);
  const createHealthcheckCase = useCreateHealthcheckCase();
  const createMismatchCase = useCreateMismatchCase();
  const { upload: retryUpload, error: retryUploadError } = useUploadImages();
  const insertImageUploadScreen = useInsertImageUploadScreen();
  const preReauthentication = usePreReauthentication();

  function nextScreen() {
    const next = screenIndex + 1;
    setScreenIndex(next);
  }

  useEffect(() => {
    if (retryUploadError) {
      if (isAxiosAuthenticationError(retryUploadError)) {
        preReauthentication();
      } else {
        insertImageUploadScreen();
      }
    }
  }, [retryUploadError]);

  useEffect(() => {
    const lastScreenName = journeyScreens[screenIndex - 1];
    const nextScreenName = journeyScreens[screenIndex];

    if (lastScreenName === 'enter-msns') {
      createMismatchCase();
    } else if (nextScreenName === 'complete') {
      if (lastScreenName == 'reauthentication') {
        if (journeyScreens.includes('image-upload')) {
          void retryUpload();
        } else {
          createHealthcheckCase();
        }
      } else if (lastScreenName != 'image-upload') {
        createHealthcheckCase();
      }
    }
  }, [screenIndex]);

  function prevScreen() {
    setScreenIndex(screenIndex - 1);
  }

  const defaultScreenProps: NavigationProps = {
    onBack: prevScreen,
    onNext: nextScreen,
  };

  function whichScreen(s: ScreenName | undefined) {
    switch (s) {
      case undefined:
      case 'welcome':
        return <Welcome onComplete={nextScreen} />;
      case 'account-details':
        return <AccountDetails onComplete={nextScreen} />;
      case 'open-case':
        return <OpenCase />;
      case 'confirm-msns':
        return <ConfirmMsns {...defaultScreenProps} />;
      case 'book-install':
        return <BookInstall {...defaultScreenProps} />;
      case 'enter-msns':
        return <EnterMsns {...defaultScreenProps} />;
      case 'wan-state':
        return <WanLight {...defaultScreenProps} />;
      case 'han-state':
        return <HanLight {...defaultScreenProps} />;
      case 'elec-commisioned':
        return <ElecCommisioned {...defaultScreenProps} />;
      case 'gas-commisioned':
        return <GasCommisioned {...defaultScreenProps} />;
      case 'elec-reading':
        return <ElecReading {...defaultScreenProps} />;
      case 'elec-two-rate-reading':
        return <ElecTwoRateReading {...defaultScreenProps} />;
      case 'gas-reading':
        return <GasReading {...defaultScreenProps} />;
      case 'meter-location':
        return <MeterLocation {...defaultScreenProps} />;
      case 'mobile-signal':
        return <MobileSignal {...defaultScreenProps} />;
      case 'meter-distance':
        return <MeterDistance {...defaultScreenProps} />;
      case 'meters-in-comms':
        return <MetersInComms {...defaultScreenProps} />;
      case 'thick-walls':
        return <ThickWalls {...defaultScreenProps} />;
      case 'tell-us-about-the-problem':
        return <TellUsAboutTheProblem {...defaultScreenProps} />;
      case 'reauthentication':
        return <Reauthenticate onComplete={nextScreen} />;
      case 'image-upload':
        return <ImageUpload onComplete={nextScreen} />;
      case 'complete':
        return <Complete />;
      default:
        return <FormSection>Unknown screen</FormSection>;
    }
  }

  return (
    <div className="wrapper">
      <Header />
      <main>{whichScreen(journeyScreens[screenIndex])}</main>
      <Footer />
    </div>
  );
};

export default Wrapper;
