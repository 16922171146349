import { Radio, RadioField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React from 'react';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import SignalHelperLink from '../components/mobile-signal-link/SignalHelperLink';
import NextButton from '../components/next-button/NextButton';
import RadioSet from '../components/radio-set/RadioSet';
import StepCounter from '../components/step-counter/StepCounter';
import { MobileSignal as MobileSignalType, stateAtom } from '../state';
import { NavigationProps } from './types';

const atom = focusAtom(stateAtom, (optic) => optic.prop('mobileSignal'));

const MobileSignal: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [state = 'Average', setState] = useAtom(atom);

  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      <RadioSet>
        <RadioField
          name="mobileSignal"
          label="How is the mobile signal next to the meter?"
          defaultValue={state}
          onValueChange={(value) => setState(value as MobileSignalType)}
        >
          <SignalHelperLink />
          <Radio label="Good" id="mobileSignalGood" value="Good" />
          <Radio label="Average" id="mobileSignalAverage" value="Average" />
          <Radio label="Poor" id="mobileSignalPoor" value="Poor" />
        </RadioField>
      </RadioSet>
      <NextButton onClick={onNext} trackOptions={['Mobile signal entered']} />
    </FormSection>
  );
};

export default MobileSignal;
