import { Radio, RadioField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React from 'react';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import NextButton from '../components/next-button/NextButton';
import RadioSet from '../components/radio-set/RadioSet';
import StepCounter from '../components/step-counter/StepCounter';
import { MeterLocation as MeterLocationType, stateAtom } from '../state';
import { NavigationProps } from './types';

const atom = focusAtom(stateAtom, (optic) => optic.prop('meterLocation'));

const optionLabels: MeterLocationType[] = [
  'Outside - front of home',
  'Outside - back of home',
  'Basement',
  'Inside - ground floor',
  'Inside - first floor',
  'Inside - second floor or above',
];

const MeterLocation: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [state = 'Inside - ground floor', setState] = useAtom(atom);

  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      <RadioSet>
        <RadioField
          name="meterLocation"
          label="Where is your electricity meter?"
          hint="This helps us know of anywhere that could be blocking the signal."
          defaultValue={state}
          onValueChange={(value) => setState(value as MeterLocationType)}
        >
          {optionLabels.map((label, index) => (
            <Radio
              key={index}
              label={label}
              value={label}
              id={`meterLocation${index.toString()}`}
              data-test={`meter-location-${index.toString()}`}
            />
          ))}
        </RadioField>
      </RadioSet>
      <NextButton onClick={onNext} trackOptions={['Meter location entered']} />
    </FormSection>
  );
};

export default MeterLocation;
