import { NavigationProps } from './types';
import { FuelType, MeterType } from '../../__generated__/graphql';
import BackButton from '../components/back-button/BackButton';
import FormSection from '../components/form-section/FormSection';
import HowFindThisLink, { ImageType } from '../components/how-find-this-link/HowFindThisLink';
import NextButton from '../components/next-button/NextButton';
import StepCounter from '../components/step-counter/StepCounter';
import { meterTypeAtom, stateAtom } from '../state';
import { validateReading } from '../utils';
import { P, Stack, TextField } from '@ovotech/nebula';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import React, { ChangeEvent, useState } from 'react';

const r1ReadingAtom = focusAtom(stateAtom, (optic) => optic.prop('elecR1Reading'));
const r2ReadingAtom = focusAtom(stateAtom, (optic) => optic.prop('elecR2Reading'));

function getError(
  label: string,
  state: string | undefined,
  submitted: boolean,
): string | undefined {
  if (!submitted) {
    return;
  }
  if (!state) {
    return `Your ${label}  meter reading is required`;
  }
  if (!validateReading(state)) {
    return `Please enter a valid ${label} meter reading`;
  }
}

const ElecTwoRateReading: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [meterType] = useAtom(meterTypeAtom);
  const [r1Reading = '', setR1Reading] = useAtom(r1ReadingAtom);
  const [r2Reading = '', setR2Reading] = useAtom(r2ReadingAtom);
  const [submitted, setSubmitted] = useState(false);

  function onNextClicked() {
    if (!getError('R1', r1Reading, true) && !getError('R2', r2Reading, true)) {
      onNext();
    }
    setSubmitted(true);
  }

  function r1ReadingChanged(e: ChangeEvent<HTMLInputElement>) {
    setR1Reading(e.target.value);
  }

  function r2ReadingChanged(e: ChangeEvent<HTMLInputElement>) {
    setR2Reading(e.target.value);
  }

  return (
    <FormSection>
      <BackButton onClick={onBack} />
      <StepCounter />
      {meterType === MeterType.S1 && <P>Press the 6 button on your electricity meter.</P>}
      {meterType === MeterType.S2 && <P>Press the A button on your electricity meter.</P>}
      <P>
        The display will switch between two readings - it'll come up first as R1:XXXXX IMP KWH, then
        switch a few seconds later to R2: XXXXX IMP KWH
      </P>
      <P>Please provide each of these readings that appear before IMP KWH</P>
      <HowFindThisLink
        meterType={meterType}
        fuel={FuelType.Electricity}
        imageType={ImageType.READING_TWO_RATE}
      />
      <Stack spaceBetween={5}>
        <TextField
          id="r1Reading"
          data-test="elec-r1-reading-input"
          label="Electricity R1 reading"
          error={getError('R1', r1Reading, submitted)}
          onChange={r1ReadingChanged}
          value={r1Reading}
        />
        <TextField
          id="r2Reading"
          data-test="elec-r2-reading-input"
          label="Electricity R2 reading"
          error={getError('R2', r2Reading, submitted)}
          onChange={r2ReadingChanged}
          value={r2Reading}
        />
      </Stack>
      <NextButton onClick={onNextClicked} trackOptions={['Elec two rate reading entered']} />
    </FormSection>
  );
};

export default ElecTwoRateReading;
