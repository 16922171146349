import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type Case = {
  __typename?: 'Case';
  id: Scalars['String']['output'];
};

export type CreateHealthCheckCaseInput = {
  caseComments: Scalars['String']['input'];
  meterType: Scalars['String']['input'];
};

export type CreateMismatchCaseInput = {
  elecCustomerMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  elecSystemMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gasCustomerMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gasSystemMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DpaCheckInput = {
  accountNumber: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type EbssPayment = {
  __typename?: 'EbssPayment';
  brand?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['DateTime']['output']>;
  monthlyValueAmount?: Maybe<Scalars['Float']['output']>;
  paymentReferenceNumber?: Maybe<Scalars['String']['output']>;
  paymentRequestCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** bacs | acc | smt | vou */
  refundDueVia?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum FuelType {
  Electricity = 'Electricity',
  Gas = 'Gas'
}

export enum MeterType {
  Other = 'OTHER',
  S1 = 'S1',
  S2 = 'S2'
}

export type Mutation = {
  __typename?: 'Mutation';
  createHealthCheckCase?: Maybe<Case>;
  createMismatchCase?: Maybe<Array<Case>>;
};


export type MutationCreateHealthCheckCaseArgs = {
  details: CreateHealthCheckCaseInput;
};


export type MutationCreateMismatchCaseArgs = {
  details: CreateMismatchCaseInput;
};

export type Query = {
  __typename?: 'Query';
  areAccountDetailsCorrect: Scalars['Boolean']['output'];
  areDpaDetailsCorrect: Scalars['Boolean']['output'];
  ebssPayments: Array<EbssPayment>;
  hasOpenSalesforceCase: Scalars['Boolean']['output'];
  supplyPoints: Array<SupplyPoint>;
  warmHomeDiscountState?: Maybe<WarmHomeDiscountState>;
};


export type QueryAreAccountDetailsCorrectArgs = {
  details: VerifyAccountInput;
};


export type QueryAreDpaDetailsCorrectArgs = {
  details: DpaCheckInput;
};

export type SupplyPoint = {
  __typename?: 'SupplyPoint';
  fuel: FuelType;
  isTwoRate?: Maybe<Scalars['Boolean']['output']>;
  mpxn: Scalars['String']['output'];
  msn?: Maybe<Scalars['String']['output']>;
  nonComms?: Maybe<Scalars['Boolean']['output']>;
  type: MeterType;
};

export type VerifyAccountInput = {
  accountNumber: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type WarmHomeDiscountState = {
  __typename?: 'WarmHomeDiscountState';
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  paidToGas?: Maybe<Scalars['Boolean']['output']>;
  paymentCommDate?: Maybe<Scalars['DateTime']['output']>;
  paymentIssuedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Smets2 | Dppm | Cheque | Smets1 | Credit */
  paymentMethod?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** PaymentSuccess | ExternalValidationFailure | AwaitingPayment | UnknownSupply | Duplicate | ApprovalException | ApprovalPending | ExternalValidation | PaymentInProgress */
  stateGroup?: Maybe<Scalars['String']['output']>;
};

export type CreateHealthCheckCaseMutationVariables = Exact<{
  details: CreateHealthCheckCaseInput;
}>;


export type CreateHealthCheckCaseMutation = { __typename?: 'Mutation', createHealthCheckCase?: { __typename?: 'Case', id: string } | null };

export type CreateMismatchCaseMutationVariables = Exact<{
  details: CreateMismatchCaseInput;
}>;


export type CreateMismatchCaseMutation = { __typename?: 'Mutation', createMismatchCase?: Array<{ __typename?: 'Case', id: string }> | null };

export type GetAccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountDetailsQuery = { __typename?: 'Query', hasOpenSalesforceCase: boolean, supplyPoints: Array<{ __typename?: 'SupplyPoint', mpxn: string, fuel: FuelType, type: MeterType, msn?: string | null, isTwoRate?: boolean | null, nonComms?: boolean | null }> };

export type VerifyAccountQueryVariables = Exact<{
  details: VerifyAccountInput;
}>;


export type VerifyAccountQuery = { __typename?: 'Query', areAccountDetailsCorrect: boolean };


export const CreateHealthCheckCaseDocument = gql`
    mutation CreateHealthCheckCase($details: CreateHealthCheckCaseInput!) {
  createHealthCheckCase(details: $details) {
    id
  }
}
    `;
export type CreateHealthCheckCaseMutationFn = Apollo.MutationFunction<CreateHealthCheckCaseMutation, CreateHealthCheckCaseMutationVariables>;

/**
 * __useCreateHealthCheckCaseMutation__
 *
 * To run a mutation, you first call `useCreateHealthCheckCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHealthCheckCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHealthCheckCaseMutation, { data, loading, error }] = useCreateHealthCheckCaseMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useCreateHealthCheckCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateHealthCheckCaseMutation, CreateHealthCheckCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHealthCheckCaseMutation, CreateHealthCheckCaseMutationVariables>(CreateHealthCheckCaseDocument, options);
      }
export type CreateHealthCheckCaseMutationHookResult = ReturnType<typeof useCreateHealthCheckCaseMutation>;
export type CreateHealthCheckCaseMutationResult = Apollo.MutationResult<CreateHealthCheckCaseMutation>;
export type CreateHealthCheckCaseMutationOptions = Apollo.BaseMutationOptions<CreateHealthCheckCaseMutation, CreateHealthCheckCaseMutationVariables>;
export const CreateMismatchCaseDocument = gql`
    mutation CreateMismatchCase($details: CreateMismatchCaseInput!) {
  createMismatchCase(details: $details) {
    id
  }
}
    `;
export type CreateMismatchCaseMutationFn = Apollo.MutationFunction<CreateMismatchCaseMutation, CreateMismatchCaseMutationVariables>;

/**
 * __useCreateMismatchCaseMutation__
 *
 * To run a mutation, you first call `useCreateMismatchCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMismatchCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMismatchCaseMutation, { data, loading, error }] = useCreateMismatchCaseMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useCreateMismatchCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateMismatchCaseMutation, CreateMismatchCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMismatchCaseMutation, CreateMismatchCaseMutationVariables>(CreateMismatchCaseDocument, options);
      }
export type CreateMismatchCaseMutationHookResult = ReturnType<typeof useCreateMismatchCaseMutation>;
export type CreateMismatchCaseMutationResult = Apollo.MutationResult<CreateMismatchCaseMutation>;
export type CreateMismatchCaseMutationOptions = Apollo.BaseMutationOptions<CreateMismatchCaseMutation, CreateMismatchCaseMutationVariables>;
export const GetAccountDetailsDocument = gql`
    query GetAccountDetails {
  hasOpenSalesforceCase
  supplyPoints {
    mpxn
    fuel
    type
    msn
    isTwoRate
    nonComms
  }
}
    `;

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
      }
export function useGetAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
        }
export function useGetAccountDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
        }
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
export type GetAccountDetailsSuspenseQueryHookResult = ReturnType<typeof useGetAccountDetailsSuspenseQuery>;
export type GetAccountDetailsQueryResult = Apollo.QueryResult<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>;
export const VerifyAccountDocument = gql`
    query VerifyAccount($details: VerifyAccountInput!) {
  areAccountDetailsCorrect(details: $details)
}
    `;

/**
 * __useVerifyAccountQuery__
 *
 * To run a query within a React component, call `useVerifyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAccountQuery({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useVerifyAccountQuery(baseOptions: Apollo.QueryHookOptions<VerifyAccountQuery, VerifyAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyAccountQuery, VerifyAccountQueryVariables>(VerifyAccountDocument, options);
      }
export function useVerifyAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyAccountQuery, VerifyAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyAccountQuery, VerifyAccountQueryVariables>(VerifyAccountDocument, options);
        }
export function useVerifyAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyAccountQuery, VerifyAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyAccountQuery, VerifyAccountQueryVariables>(VerifyAccountDocument, options);
        }
export type VerifyAccountQueryHookResult = ReturnType<typeof useVerifyAccountQuery>;
export type VerifyAccountLazyQueryHookResult = ReturnType<typeof useVerifyAccountLazyQuery>;
export type VerifyAccountSuspenseQueryHookResult = ReturnType<typeof useVerifyAccountSuspenseQuery>;
export type VerifyAccountQueryResult = Apollo.QueryResult<VerifyAccountQuery, VerifyAccountQueryVariables>;