import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import { useEffect } from 'react';
import { MeterType, useCreateHealthCheckCaseMutation } from '../../__generated__/graphql';
import { useTrack } from '../analytics';
import { createHealthCheckComments } from '../comments';
import { stateAtom } from '../state';
import { isGraphQLAuthenticationError } from '../utils';
import { usePreReauthentication } from './usePreReauthentication';
import { useInsertImageUploadScreen } from './useInsertImageUploadScreen';

const caseCreationStatusAtom = focusAtom(stateAtom, (optic) => optic.prop('caseCreationStatus'));
const caseIdsAtom = focusAtom(stateAtom, (optic) => optic.prop('caseIds'));

export function useCreateHealthcheckCase(): () => void {
  const [, setCaseCreationStatus] = useAtom(caseCreationStatusAtom);
  const [, setCaseIds] = useAtom(caseIdsAtom);
  const [state] = useAtom(stateAtom);
  const [createCase, { error, data }] = useCreateHealthCheckCaseMutation();
  const track = useTrack();
  const insertImageUploadScreen = useInsertImageUploadScreen();
  const preReauthentication = usePreReauthentication();

  function execute() {
    track('Healthcheck case created');
    void createCase({
      variables: {
        details: {
          caseComments: createHealthCheckComments(state),
          meterType: state.meterType as MeterType,
        },
      },
    }).catch((err) => {
      console.error(err.message);
    });
  }

  useEffect(() => {
    if (isGraphQLAuthenticationError(error)) {
      track('Healthcheck case creation auth error', undefined, false);
      preReauthentication();
    } else if (error || (data && !data.createHealthCheckCase)) {
      track('Healthcheck case creation error', undefined, false);
      setCaseCreationStatus('error');
    } else if (data && data.createHealthCheckCase?.id) {
      track('Healthcheck case creation success', undefined, false);
      setCaseCreationStatus('submitted');
      setCaseIds([data.createHealthCheckCase.id]);
      insertImageUploadScreen();
    }
  }, [error, data]);

  return execute;
}
