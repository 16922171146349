import { MeterType } from '../__generated__/graphql';
import { getJourneyOptions } from './journeys';
import { State } from './state';

type NonOptionalState = {
  [P in keyof State]-?: State[P];
};

export function createHealthCheckComments(state: State): string {
  const state_ = state as NonOptionalState;
  const { hasGas, isTwoRate, meterType } = getJourneyOptions(state_.supplyPoints);

  return [
    'Meter details from Orion:',
    ...state_.supplyPoints
      .map((s) => [
        `${s.fuel} Meter:`,
        `MPXN: ${s.mpxn}`,
        `MSN: ${s.msn ?? '-'}`,
        `Simplified meter type: ${s.type}`,
        `Two rate: ${getYesNo(s.isTwoRate)}`,
        '',
      ])
      .flat(),
    '',
    'Customer responses:',
    `WAN state: ${state_.wanState}`,
    `HAN state: ${state_.hanState}`,
    ...(meterType == MeterType.S1
      ? [
          `Elec commisioned: ${getYesNo(state_.isElecCommisioned)}`,
          ...(hasGas ? [`Gas commisioned: ${getYesNo(state_.isGasCommisioned)}`] : []),
        ]
      : []),
    ...(isTwoRate
      ? [`Elec R1 reading: ${state_.elecR1Reading}`, `Elec R2 reading: ${state_.elecR2Reading}`]
      : [`Elec anytime reading: ${state_.elecAnytimeReading}`]),
    ...(hasGas ? [`Gas reading: ${state_.gasReading}`] : []),
    `Meter location: ${state_.meterLocation}`,
    `Mobile signal: ${state_.mobileSignal}`,
    ...(hasGas
      ? [
          `Distance between meters: ${
            state_.isDistanceMoreThanTenMeters ? 'more than 10 metres' : '10 metres or less'
          }`,
          `Thick walls: ${getYesNo(state_.isThickWalls)}`,
        ]
      : []),
    `Customer has been in touch with us about this problem before: ${getYesNo(
      state_.previousContact,
    )}`,
    `Customer description of problem: ${
      state_.customerInput ? state_.customerInput : 'No description given'
    }`,
    `IHD issue: ${getYesNo(state_.ihdIssue)}`,
  ].join('\n');
}

function getYesNo(value: boolean | undefined | null): string {
  return value ? 'Yes' : 'No';
}
