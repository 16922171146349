import { Modal, P } from '@ovotech/nebula';
import React, { PropsWithChildren } from 'react';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import './LoadingModal.css';

interface Props extends PropsWithChildren {
  isOpen: boolean;
}

const LoadingModal: React.FC<Props> = ({ isOpen, children }) => {
  return (
    <Modal className="loading-modal" title="" isOpen={isOpen} isDismissable={false}>
      <LoadingSpinner />
      <div className="loading-modal_content">
        <P>{children}</P>
      </div>
    </Modal>
  );
};

export default LoadingModal;
