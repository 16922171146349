import { P } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import AccountDetailsForm from '../../components/account-details-form/AccountDetailsForm';
import { useGetAccountDetailsLazyQuery } from '../../../__generated__/graphql';
import {
  getJourneyOptions,
  makeOpenCaseJourney,
  makeMissingMsnJourney,
  makeJourney,
  makeUnsupportedMeterJourney,
} from '../../journeys';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import { journeyScreensAtom, meterTypeAtom, stateAtom } from '../../state';
import { useTrack } from '../../analytics';

const supplyPointsAtom = focusAtom(stateAtom, (optic) => optic.prop('supplyPoints'));

interface Props {
  onComplete: () => void;
}

const AccountDetails: React.FC<Props> = ({ onComplete }) => {
  const [, setSupplyPoints] = useAtom(supplyPointsAtom);
  const [, setJourneyScreens] = useAtom(journeyScreensAtom);
  const [, setMeterType] = useAtom(meterTypeAtom);
  const track = useTrack();

  const [
    getAccountDetails,
    {
      data: accountDetailsQueryData,
      error: accountDetailsQueryError,
      loading: accountDetailsQueryLoading,
    },
  ] = useGetAccountDetailsLazyQuery();

  function onVerifyResult(passed: boolean) {
    if (passed) {
      void getAccountDetails();
    }
  }

  useEffect(() => {
    if (!accountDetailsQueryLoading && accountDetailsQueryData) {
      const { hasOpenSalesforceCase, supplyPoints } = accountDetailsQueryData;
      setSupplyPoints(supplyPoints);
      const journeyOptions = getJourneyOptions(supplyPoints);
      if (hasOpenSalesforceCase) {
        setJourneyScreens(makeOpenCaseJourney());
      } else if (journeyOptions.noMsns) {
        setJourneyScreens(makeMissingMsnJourney());
      } else if (supplyPoints.length > 0) {
        setMeterType(journeyOptions.meterType);
        setJourneyScreens(makeJourney(journeyOptions));
      } else {
        setJourneyScreens(makeUnsupportedMeterJourney());
      }
      track(
        'Account details',
        { hasOpenSalesforceCase, numSupplyPoints: supplyPoints.length, ...journeyOptions },
        false,
      );
      onComplete();
    }
    if (accountDetailsQueryError) {
      track('Account details error', undefined, false);
    }
  }, [accountDetailsQueryData, accountDetailsQueryLoading, accountDetailsQueryError]);

  return (
    <AccountDetailsForm
      errorMessage={accountDetailsQueryError?.message}
      isLoading={accountDetailsQueryLoading}
      message={
        <P>
          To make sure we can help find out what's wrong with your meters, we’ll ask you a questions
          about your account.
        </P>
      }
      trackingPrefix="Account verification"
      onVerifyResult={onVerifyResult}
    />
  );
};

export default AccountDetails;
