import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import { useEffect } from 'react';
import { FuelType, useCreateMismatchCaseMutation } from '../../__generated__/graphql';
import { useTrack } from './../analytics';
import { stateAtom } from '../state';
import { isGraphQLAuthenticationError } from '../utils';
import { usePreReauthentication } from './usePreReauthentication';
import { useInsertImageUploadScreen } from './useInsertImageUploadScreen';

const caseCreationStatusAtom = focusAtom(stateAtom, (optic) => optic.prop('caseCreationStatus'));
const caseIdsAtom = focusAtom(stateAtom, (optic) => optic.prop('caseIds'));

export function useCreateMismatchCase(): () => void {
  const [, setCaseCreationStatus] = useAtom(caseCreationStatusAtom);
  const [, setCaseIds] = useAtom(caseIdsAtom);
  const [state] = useAtom(stateAtom);
  const [createCase, { error, data }] = useCreateMismatchCaseMutation();
  const track = useTrack();
  const insertImageUploadScreen = useInsertImageUploadScreen();
  const preReauthentication = usePreReauthentication();

  function execute(): void {
    track('Mismatch case created');
    void createCase({
      variables: {
        details: {
          elecCustomerMsns: state.elecCustomerMsns,
          elecSystemMsns: state.supplyPoints
            ?.filter((s) => s.fuel === FuelType.Electricity)
            .map((s) => s.msn ?? null),
          gasCustomerMsns: state.gasCustomerMsns,
          gasSystemMsns: state.supplyPoints
            ?.filter((s) => s.fuel === FuelType.Gas)
            .map((s) => s.msn ?? null),
        },
      },
    }).catch((err) => {
      console.error(err.message);
    });
  }

  useEffect(() => {
    if (isGraphQLAuthenticationError(error)) {
      track('Mismatch case creation auth error', undefined, false);
      preReauthentication();
    } else if (error || (data && !data.createMismatchCase)) {
      track('Mismatch case creation error', undefined, false);
      setCaseCreationStatus('error');
    } else if (data && Array.isArray(data.createMismatchCase)) {
      track('Mismatch case creation success', undefined, false);
      setCaseCreationStatus('submitted');
      setCaseIds(data.createMismatchCase.map((d) => d.id));
      insertImageUploadScreen();
    }
  }, [error, data]);

  return execute;
}
