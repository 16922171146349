import { Li, Modal, P, TextLink, Ul } from '@ovotech/nebula';
import React, { useState } from 'react';
import { useTrack } from '../../analytics';
import './SignalHelperLink.css';

const SignalHelperLink: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const track = useTrack();
  function linkClicked() {
    track('Signal helper link clicked');
    setIsOpen(true);
  }
  return (
    <div className="signal-helper-link">
      <TextLink onClick={linkClicked} data-test="signal-helper-link">
        How do I know if my signal's good or bad?
      </TextLink>
      <Modal
        className="signal-helper-link_modal"
        data-test="signal-helper-modal"
        title="How to check for a bad smart meter signal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <P>
          If you're not sure whether you have bad smart meter signal, there's a few ways to tell:
        </P>
        <Ul className="signal-helper-link_list">
          <Li>You can see a "Connection Lost" or "Data Lost" message</Li>
          <Li>One or both fuels aren’t showing energy use data</Li>
          <Li>Cost and Consumption data is showing as dashes rather than kWh or £s</Li>
          <Li>You can't see any information at all</Li>
        </Ul>
      </Modal>
    </div>
  );
};

export default SignalHelperLink;
