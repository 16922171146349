import { P, PrimaryCTAButton, SecondaryCTAButton, Stack } from '@ovotech/nebula';
import React, { ChangeEvent, FormEvent } from 'react';
import FormSection from '../../components/form-section/FormSection';
import './ImageUpload.css';
import ErrorNotification from '../../components/error-notification/ErrorNotification';
import { useTrack } from '../../analytics';
import { imageFilesAtom } from '../../state';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import LoadingModal from '../../components/loading-modal/LoadingModal';
import { defaultMaxImageBytes } from '../../constants';
import { useUploadImages } from '../../hooks/useUploadImages';
import { usePreReauthentication } from '../../hooks/usePreReauthentication';
import { isAxiosAuthenticationError } from '../../utils';

// image limits copied from backend
const maxImages = 4;

interface Props {
  maxImageBytes?: number;
  onComplete: () => void;
}

const ImageUpload: React.FC<Props> = ({
  maxImageBytes = defaultMaxImageBytes,
  onComplete,
}): JSX.Element => {
  const [fileList = [], setFileList] = useAtom(imageFilesAtom);
  const track = useTrack();
  const { loading: isLoading, error, upload, complete } = useUploadImages(maxImageBytes);
  const preReauthentication = usePreReauthentication();

  function isImageTooBig(file: File): boolean {
    return file.size > maxImageBytes;
  }

  function onFileChange(e: ChangeEvent<HTMLInputElement>) {
    track('Image file selected');
    setFileList([...fileList, ...Array.from(e.target.files ?? [])]);
  }

  useEffect(() => {
    if (isAxiosAuthenticationError(error)) {
      preReauthentication();
      onComplete();
    } else if (complete && !error) {
      onComplete();
    }
  }, [error, complete]);

  function onSubmit(e: FormEvent) {
    e.preventDefault();

    if (fileList.length === 0) {
      track('No images selected');
      onComplete();
    } else {
      void upload();
    }
  }

  function removeFile(index: number) {
    track('Image file removed');
    const newList = [...fileList];
    newList.splice(index, 1);
    setFileList(newList);
  }

  return (
    <FormSection className="image-upload">
      <LoadingModal isOpen={isLoading}>One moment please, while we upload your photos</LoadingModal>
      <form onSubmit={onSubmit}>
        <Stack spaceBetween={2}>
          <P>You can also upload photos showing us where your meter readings can be found.</P>
          {!!error && (
            <ErrorNotification id="upload-error-msg">
              Sorry, there was a problem uploading your photos.
            </ErrorNotification>
          )}
          {fileList.length > 0 && (
            <ul className="image-upload_file-list">
              {fileList.map((file, index) => {
                const isTooBig = isImageTooBig(file);
                return (
                  <li
                    key={index}
                    data-test={`image-${index}`}
                    {...(isTooBig
                      ? {
                          className: 'image-upload_file-too-big',
                        }
                      : {})}
                  >
                    <div className="image-upload_file-label">
                      {isTooBig
                        ? 'Your image is too large. Please re-upload your photo.'
                        : `${file.name} selected`}
                    </div>
                    <img src={URL.createObjectURL(file)} />
                    <SecondaryCTAButton
                      type="button"
                      data-test={`delete-btn-${index}`}
                      onClick={() => removeFile(index)}
                    >
                      Delete photo
                    </SecondaryCTAButton>
                  </li>
                );
              })}
            </ul>
          )}
          {fileList.length < maxImages && (
            <div>
              <label className="image-upload_file-input-label" tabIndex={0}>
                <span>{fileList.length > 0 ? 'Upload new photo' : 'Upload photo'}</span>
                <input
                  className="image-upload_file-input"
                  type="file"
                  accept="image/png,image/jpg,image/jpeg"
                  onChange={onFileChange}
                />
              </label>
            </div>
          )}
          <div>
            <PrimaryCTAButton type="submit" data-test="upload-btn">
              {fileList.length > 0 ? 'Submit photos' : 'Submit without photos'}
            </PrimaryCTAButton>
          </div>
        </Stack>
      </form>
    </FormSection>
  );
};

export default ImageUpload;
