import { Heading2, P, PrimaryCTALink, SecondaryCTALink } from '@ovotech/nebula';
import React from 'react';
import { useTrack } from '../../analytics';
import FormSection from '../../components/form-section/FormSection';
import { useResetStateOnBeforeUnload } from '../../hooks/useResetStateOnBeforeUnload';
import { NavigationProps } from '../types';
import './MetersInComms.css';

const MetersInComms: React.FC<NavigationProps> = () => {
  useResetStateOnBeforeUnload();
  const track = useTrack();

  function handleContactUsClick() {
    track('In-Comms meters Contact Us Link Clicked');
  }
  function handleHelpCentreClick() {
    track('In-Comms meters Help Centre CTA clicked');
  }

  return (
    <FormSection className="meters-in-comms">
      <Heading2>Your smart meters are talking to us</Heading2>
      <P>You don't need to fill in this form as we're getting updates from your meters.</P>
      <P>If you need help, please get in touch or use our Help Centre.</P>
      <div className="get-in-touch-link">
        <PrimaryCTALink
          href="https://www.ovoenergy.com/help#contact_us_container"
          iconRight="chevron-right"
          onClick={handleContactUsClick}
          data-test="contact-us"
        >
          Get in touch
        </PrimaryCTALink>
      </div>
      <div className="get-in-touch-link">
        <SecondaryCTALink
          href="https://help.ovoenergy.com/"
          iconRight="chevron-right"
          onClick={handleHelpCentreClick}
          data-test="visit-help-centre-button"
        >
          Visit Help centre
        </SecondaryCTALink>
      </div>
    </FormSection>
  );
};

export default MetersInComms;
