import { PrimaryCTALink } from '@ovotech/nebula';
import React from 'react';
import { useTrack } from '../../analytics';
import './HelpCentreCTA.css';

interface Props {
  trackOptions: [string, Record<string, unknown>?];
}

const HelpCentreCTA: React.FC<Props> = ({ trackOptions: [event, payload] }) => {
  const track = useTrack();
  function handleClick() {
    track(event, payload);
  }
  return (
    <div className="help-centre_cta">
      <PrimaryCTALink
        iconRight="chevron-right"
        href="https://help.ovoenergy.com/"
        onClick={handleClick}
      >
        Back to Help Centre
      </PrimaryCTALink>
    </div>
  );
};

export default HelpCentreCTA;
