import React from 'react';
import { themeConfig } from '@ovotech/ovoenergy-theme';
import './Header.css';

const Logo: React.FC = () => {
  return <themeConfig.logo.variants.default />;
};

const Header: React.FC = () => {
  return (
    <header className="header">
      <span className="header_logo">
        <Logo />
      </span>
      <span className="header_divider">|</span>
      <span className="header_title">Smart meter help</span>
    </header>
  );
};

export default Header;
