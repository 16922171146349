import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { initialState, stateAtom } from '../state';

export function useResetStateOnBeforeUnload(): void {
  const [, setState] = useAtom(stateAtom);

  useEffect(() => {
    setTimeout(() => {
      window.onbeforeunload = () => {
        setState(initialState);
      };
    }, 2000);
  }, []);
}
