import { Heading2, P, PrimaryCTALink, SecondaryCTALink } from '@ovotech/nebula';
import React from 'react';
import { useTrack } from '../../analytics';
import BackButton from '../../components/back-button/BackButton';
import FormSection from '../../components/form-section/FormSection';
import { useResetStateOnBeforeUnload } from '../../hooks/useResetStateOnBeforeUnload';
import { NavigationProps } from '../types';
import './BookInstall.css';
import FeedbackUI from '../../components/feedback-ui/FeedbackUI';

const BookInstall: React.FC<NavigationProps> = ({ onBack }) => {
  useResetStateOnBeforeUnload();
  const track = useTrack();

  function handleBookInstallClick() {
    track('Book install CTA clicked');
  }
  function handleHelpCentreClick() {
    track('Book install Help Centre CTA clicked');
  }

  return (
    <FormSection className="book-install">
      <BackButton onClick={onBack} />
      <Heading2>It looks like you don't yet have a smart meter installed</Heading2>
      <P>
        Book a Smart meter installation quickly and easily online. You can also find out more about
        how smart meters work through the OVO Help centre
      </P>
      <div className="book-install_link">
        <PrimaryCTALink
          href="https://smart-booking.ovoenergy.com/getting-started"
          iconRight="chevron-right"
          onClick={handleBookInstallClick}
          data-test="book-install-button"
        >
          Book an installation
        </PrimaryCTALink>
      </div>
      <div className="book-install_link">
        <SecondaryCTALink
          href="https://help.ovoenergy.com/"
          iconRight="chevron-right"
          onClick={handleHelpCentreClick}
          data-test="visit-help-centre-button"
        >
          Visit Help centre
        </SecondaryCTALink>
      </div>
      <FeedbackUI />
    </FormSection>
  );
};

export default BookInstall;
