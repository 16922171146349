import { Heading2, P, TextLink } from '@ovotech/nebula';
import React from 'react';
import NextButton from '../../components/next-button/NextButton';
import './Welcome.css';

interface Props {
  onComplete: () => void;
}

const Welcome: React.FC<Props> = ({ onComplete }) => {
  return (
    <section className="welcome">
      <div className="welcome_back" data-test="help-centre-button">
        <a href="https://help.ovoenergy.com/">Back to the Help Centre</a>
      </div>
      <div className="welcome_content">
        <Heading2>Are you having issues with your smart meter? </Heading2>
        <P>
          Tell us a few details on your meter and we’ll get back to you to help find out what’s
          wrong with your meter.
        </P>
        <P>
          If you’d like to try and diagnose the problem yourself we have plenty of{' '}
          <TextLink href="https://help.ovoenergy.com/">help articles.</TextLink>
        </P>
        <NextButton onClick={onComplete} trackOptions={['Welcome button clicked']} />
      </div>
    </section>
  );
};

export default Welcome;
