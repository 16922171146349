import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';

const readingPattern = /^[0-9]+(\.[0-9]+){0,1}$/;

export function validateReading(value: string): boolean {
  return readingPattern.test(value);
}

export function isProd(apiUrl: string): boolean {
  return apiUrl.includes('mit.ovotech');
}

export function isGraphQLAuthenticationError(error: ApolloError | undefined): boolean {
  return !!error?.graphQLErrors?.find((e) => e.extensions?.code == 'UNAUTHENTICATED');
}

export function isAxiosAuthenticationError(error: unknown): boolean {
  const axiosError = error as AxiosError;
  return axiosError && axiosError.isAxiosError && axiosError.response?.status == 401;
}
