import { Heading3, P } from '@ovotech/nebula';
import React from 'react';
import FormSection from '../components/form-section/FormSection';
import HelpCentreCTA from '../components/help-centre-cta/HelpCentreCTA';
import { useResetStateOnBeforeUnload } from '../hooks/useResetStateOnBeforeUnload';
import FeedbackUI from '../components/feedback-ui/FeedbackUI';

const OpenCase: React.FC = () => {
  useResetStateOnBeforeUnload();

  return (
    <FormSection>
      <Heading3>We’re working on your query and we’ll get back to you soon</Heading3>
      <P>
        It looks like we have an open query on your smart meters, we’ll investigate further and get
        back to you within 45 working days.
      </P>
      <HelpCentreCTA trackOptions={['Open case Help Centre CTA clicked']} />
      <FeedbackUI />
    </FormSection>
  );
};

export default OpenCase;
