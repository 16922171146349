import { P, Radio, RadioField, TextareaField } from '@ovotech/nebula';
import React, { useState } from 'react';
import BackButton from '../../components/back-button/BackButton';
import FormSection from '../../components/form-section/FormSection';
import NextButton from '../../components/next-button/NextButton';
import RadioSet from '../../components/radio-set/RadioSet';
import StepCounter from '../../components/step-counter/StepCounter';
import { NavigationProps } from '../types';
import { stateAtom } from '../../state';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import './TellUsAboutTheProblem.css';

function getError(
  inputText: string,
  characterLimit: number,
  submitted: boolean,
): boolean | undefined {
  if (!submitted) {
    return;
  }
  if (inputText.length > characterLimit) {
    return true;
  }
}

const previousContactAtom = focusAtom(stateAtom, (optic) => optic.prop('previousContact'));
const ihdIssueAtom = focusAtom(stateAtom, (optic) => optic.prop('ihdIssue'));
const customerInputAtom = focusAtom(stateAtom, (optic) => optic.prop('customerInput'));

const TellUsAboutTheProblem: React.FC<NavigationProps> = ({ onBack, onNext }) => {
  const [previousContact = false, setPreviousContact] = useAtom(previousContactAtom);
  const [ihdIssue = false, setIhdIssue] = useAtom(ihdIssueAtom);
  const [customerInput = '', setCustomerInput] = useAtom(customerInputAtom);
  const [inputText, setInputText] = useState('');
  const [characterLimit] = useState(250);
  const [submitted, setSubmitted] = useState(false);

  function onNextClicked() {
    if (inputText.length <= characterLimit) {
      onNext();
    }
    setSubmitted(true);
  }

  function handleChange(event: { target: { value: string } }) {
    const { value } = event.target;
    setInputText(value);
    setCustomerInput(value);
  }

  return (
    <FormSection className="tell-us-about-the-problem">
      <BackButton onClick={onBack} />
      <StepCounter />
      <RadioSet>
        <RadioField
          name="previousContact"
          label="Have you been in touch with us about this problem before?"
          defaultValue={previousContact ? 'true' : 'false'}
          onValueChange={(value) => setPreviousContact(value === 'true')}
        >
          <Radio label="Yes" id="previousContactTrue" value="true" />
          <Radio label="No" id="previousContactFalse" value="false" />
        </RadioField>
      </RadioSet>
      <div className="tell-us-about-the-problem_customer-input">
        <TextareaField
          id="customerInput"
          label="Briefly describe what the issue is."
          hint="For example, “I don’t think that my meter is sending readings” or “I have received an estimated bill even though I have a smart meter.”"
          rows={5}
          value={customerInput}
          onChange={handleChange}
          error={getError(inputText, characterLimit, submitted)}
          maxLength={characterLimit}
          fullWidth="always"
          resizable
        />
        <div className="tell-us-about-the-problem_character-counter">
          <P className={inputText.length >= characterLimit ? 'error-red' : ''}>
            {inputText.length}/{characterLimit} Characters
          </P>
        </div>
      </div>
      <RadioSet>
        <RadioField
          name="ihdIssue"
          label="Are you having a problem with with your In Home Display (IHD)?"
          defaultValue={ihdIssue ? 'true' : 'false'}
          onValueChange={(value) => setIhdIssue(value === 'true')}
        >
          <Radio label="Yes" id="ihdIssueTrue" value="true" data-test="ihd-issue-radio-yes" />
          <Radio label="No" id="ihdIssueFalse" value="false" data-test="ihd-issue-radio-no" />
        </RadioField>
      </RadioSet>
      <NextButton
        onClick={onNextClicked}
        trackOptions={[
          'TellUsAboutTheProblem state entered',
          {
            previousContact,
            ihdIssue,
            customerInputBoxUsed: customerInput.length > 0,
          },
        ]}
      />
    </FormSection>
  );
};

export default TellUsAboutTheProblem;
